// src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../utils/Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      // Assuming window.Telegram.WebApp.ready() and initDataUnsafe are handled elsewhere
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      const startParam = localStorage.getItem('startParam');
      
      if (telegramUser) {
        const userRef = doc(db, 'users', telegramUser.id.toString());
        const docSnap = await getDoc(userRef);

        if (!docSnap.exists()) {
          // Create a new user if not found
          const newUser = {
            telegramId: telegramUser.id,
            username: telegramUser.username || '',
            points: 0,
            referralBonus: 0,
            referralCode: startParam || generateReferralCode()
          };
          await setDoc(userRef, newUser);
          setUser(newUser);
        } else {
          // Update existing user with referral code if needed
          const existingUser = docSnap.data();
          if (!existingUser.referralCode && startParam) {
            await setDoc(userRef, { referralCode: startParam }, { merge: true });
          }
          setUser(existingUser);
        }

        // Clean up the referral code after use
        localStorage.removeItem('startParam');
      }
    };

    fetchUser();
  }, []);

  const generateReferralCode = () => {
    // Generate a random referral code (customize as needed)
    return Math.random().toString(36).substring(2, 15);
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
