// src/components/Home.js
import React from 'react';
import TopNavBar from './TopNav';
import Coin from './tap/Coin';

const Home = () => {
  return (
    <div>
      <div>
        <TopNavBar/>
        </div>
      <h2>Home</h2>
      <p>Welcome to the Telegram Tapping Game!</p>
      <div className='tap-section'>
      <Coin/>
      </div> 
    
    </div>
  );
};

export default Home;
