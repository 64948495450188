import React, { useState, useEffect } from 'react';
import BoostersModal from './BoostersModal'; // Import the Boosters modal
import Bot from './Bot'; // Import the Bot component
import './Coin.css'; // Import the CSS file for styling

const Coin = () => {
  const [points, setPoints] = useState(0); // Points state
  const [clickRate, setClickRate] = useState(null); // To show the floating point increment
  const [animating, setAnimating] = useState(false);
  const [clickLimit, setClickLimit] = useState(500); // Initial click limit
  const [maxClickLimit, setMaxClickLimit] = useState(500); // Maximum click limit
  const [rechargeRate, setRechargeRate] = useState(0.5); // Recharge rate per second
  const [earnPerClick, setEarnPerClick] = useState(1); // Earn per click
  const [showBoostersModal, setShowBoostersModal] = useState(false); // Modal visibility for Boosters
  const [showBotModal, setShowBotModal] = useState(false); // Modal visibility for Bot

  const [earnPerClickPrice, setEarnPerClickPrice] = useState(10); // Initial price for earn per click
  const [clickLimitPrice, setClickLimitPrice] = useState(50); // Initial price for click limit
  const [rechargeRatePrice, setRechargeRatePrice] = useState(30); // Initial price for recharge rate

  // Load saved data from localStorage
  useEffect(() => {
    const savedPoints = parseInt(localStorage.getItem('points')) || 0;
    const savedClickLimit = parseFloat(localStorage.getItem('clickLimit')) || 500;
    const savedMaxClickLimit = parseFloat(localStorage.getItem('maxClickLimit')) || 500;
    const savedEarnPerClick = parseInt(localStorage.getItem('earnPerClick')) || 1;
    const savedRechargeRate = parseFloat(localStorage.getItem('rechargeRate')) || 0.5;

    setPoints(savedPoints);
    setClickLimit(savedClickLimit);
    setMaxClickLimit(savedMaxClickLimit);
    setEarnPerClick(savedEarnPerClick);
    setRechargeRate(savedRechargeRate);

    // Check if there are any bot-earned points to claim
    const savedBotEarnedPoints = parseInt(localStorage.getItem('botEarnedPoints')) || 0;
    if (savedBotEarnedPoints > 0) {
      setShowBotModal(true);
    }
  }, []);

  // Save data to localStorage whenever points, clickLimit, etc., change
  useEffect(() => {
    localStorage.setItem('points', points);
    localStorage.setItem('clickLimit', clickLimit);
    localStorage.setItem('maxClickLimit', maxClickLimit);
    localStorage.setItem('earnPerClick', earnPerClick);
    localStorage.setItem('rechargeRate', rechargeRate);
  }, [points, clickLimit, maxClickLimit, earnPerClick, rechargeRate]);

  const handleClick = () => {
    if (clickLimit > 0) {
      setAnimating(true);
      setPoints(prev => prev + earnPerClick); // Increase points by earnPerClick amount
      setClickRate(`+${earnPerClick}`); // Show the increment
      setClickLimit(prev => Math.max(prev - earnPerClick, 0)); // Decrease click limit by earnPerClick

      // Remove animation class after animation ends
      setTimeout(() => {
        setAnimating(false);
      }, 500); // Duration of the pulse animation

      // Hide click rate after animation
      setTimeout(() => {
        setClickRate(null);
      }, 1000); // Duration of the floating and fading animation
    }
  };

  // Recharge click limit at the current rechargeRate
  useEffect(() => {
    const rechargeInterval = setInterval(() => {
      setClickLimit(prev => Math.min(prev + rechargeRate, maxClickLimit)); // Recharge at the current rate, max out at maxClickLimit
    }, 1000);

    return () => clearInterval(rechargeInterval); // Cleanup on unmount
  }, [rechargeRate, maxClickLimit]);

  // Handle purchase upgrade logic
  const handleUpgradePurchase = (upgradeType) => {
    if (points >= getUpgradePrice(upgradeType)) {
      switch (upgradeType) {
        case 'earnPerClick':
          setEarnPerClick(prev => prev + 1);
          setPoints(prev => prev - earnPerClickPrice);
          setEarnPerClickPrice(prev => prev + 10); // Increase the price for the next level
          break;
        case 'clickLimit':
          setClickLimit(prev => Math.min(prev + 500, maxClickLimit)); // Increase click limit by 500 but not exceeding maxClickLimit
          setMaxClickLimit(prev => prev + 500); // Increase the maximum click limit by 500
          setPoints(prev => prev - clickLimitPrice);
          setClickLimitPrice(prev => prev + 50); // Increase price
          break;
        case 'rechargeRate':
          setRechargeRate(prev => prev + 0.1); // Increase recharge rate
          setPoints(prev => prev - rechargeRatePrice);
          setRechargeRatePrice(prev => prev + 30); // Increase price
          break;
        default:
          break;
      }
    } else {
      alert('Not enough points to purchase this upgrade!');
    }
  };

  // Function to get the price for the respective upgrade
  const getUpgradePrice = (upgradeType) => {
    switch (upgradeType) {
      case 'earnPerClick':
        return earnPerClickPrice;
      case 'clickLimit':
        return clickLimitPrice;
      case 'rechargeRate':
        return rechargeRatePrice;
      default:
        return 0;
    }
  };

  return (
    <div className="coin-container">
      <div className="coin" onClick={handleClick}>
        <img className={`coin-img ${animating ? 'pulse' : ''}`} src={require('../../assets/token.png')} alt="Coin" />
        {clickRate && <div className="click-rate">{clickRate}</div>}
      </div>

      <div className="points">
        Points: {points}
      </div>
      <div className='click-d'>
        <div className="click-limit">
          Limit: {Math.round(clickLimit)}/{maxClickLimit}
        </div>

        <button className="upgrade-button" onClick={() => setShowBoostersModal(true)}>
          Boost
        </button>
        <button className="upgrade-button" onClick={() => setShowBotModal(true)}>
          Bot
        </button>
      </div>

      {/* Modal for Boosters */}
      <BoostersModal
        show={showBoostersModal}
        onClose={() => setShowBoostersModal(false)}
        points={points}
        handleUpgradePurchase={handleUpgradePurchase}
        getUpgradePrice={getUpgradePrice}
        earnPerClick={earnPerClick}
        clickLimit={clickLimit}
        rechargeRate={rechargeRate}
        setEarnPerClick={setEarnPerClick}  // Pass down setEarnPerClick
        setClickLimit={setClickLimit}      // Pass down setClickLimit
        setRechargeRate={setRechargeRate}  // Pass down setRechargeRate
        setPoints={setPoints}              // Pass down setPoints
      />

      {/* Modal for Bot */}
      <Bot
        show={showBotModal}
        onClose={() => setShowBotModal(false)}
        points={points}
        setPoints={setPoints}
      />
    </div>
  );
};

export default Coin;
