import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/Firebase';
import './TopNavBar.css';

const TopNavBar = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const telegramUserId = localStorage.getItem('telegramUserId');
        if (!telegramUserId) {
          throw new Error("Telegram user ID not found.");
        }

        const userRef = doc(db, 'users', telegramUserId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          setError("No such user found.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  // Format points with commas
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  if (loading) {
    return <div className="loading-screen">Loading...</div>;
  }

  if (error) {
    return <div className="error-screen">Error: {error}</div>;
  }

  // Set dummy data if no user data is available
  const defaultUser = {
    username: "Adventurer",
    level: '1',
    points: 1000000, // Default dummy data
    streak: '1'
  };

  const displayUser = user || defaultUser;

  return (
    <div className="top-nav-bar">
      <div className="user-info">
        <div className="username">{displayUser.username || 'Adventurer'}</div>
        <div className="level">Level: {displayUser.level || '1'}</div>
        <div className="points"> {formatNumber(displayUser.points)|| '0'}</div>
        <div className="streak">S-{displayUser.streak || 1}</div>
      </div>
    </div>
  );
};

export default TopNavBar;
