import React, { useState, useEffect } from 'react';
import './BoostersModal.css'; // Create a custom CSS file for the modal

const BoostersModal = ({
  show,
  onClose,
  points,
  handleUpgradePurchase,
  getUpgradePrice,
  earnPerClick,
  clickLimit,
  rechargeRate,
  setClickLimit,
  setEarnPerClick
}) => {
  const [instantRefillUses, setInstantRefillUses] = useState(3);
  const [boostUses, setBoostUses] = useState(3);
  const [boostActive, setBoostActive] = useState(false);

  // Load saved data from localStorage
  useEffect(() => {
    const savedInstantRefillUses = parseInt(localStorage.getItem('instantRefillUses')) || 3;
    const savedBoostUses = parseInt(localStorage.getItem('boostUses')) || 3;

    setInstantRefillUses(savedInstantRefillUses);
    setBoostUses(savedBoostUses);
  }, []);

  // Save state to localStorage
  useEffect(() => {
    localStorage.setItem('instantRefillUses', instantRefillUses);
    localStorage.setItem('boostUses', boostUses);
  }, [instantRefillUses, boostUses]);

  // Reset abilities daily at 00:00
  useEffect(() => {
    const resetBoosters = () => {
      const now = new Date();
      const nextMidnight = new Date();
      nextMidnight.setHours(24, 0, 0, 0);
      const timeUntilMidnight = nextMidnight.getTime() - now.getTime();

      setTimeout(() => {
        setInstantRefillUses(3);
        setBoostUses(3);
        localStorage.setItem('instantRefillUses', 3);
        localStorage.setItem('boostUses', 3);
        resetBoosters(); // Set up the reset for the next day
      }, timeUntilMidnight);
    };

    resetBoosters();
  }, []);

  // Handle instant refill of click limit
  const handleInstantRefill = () => {
    if (instantRefillUses > 0) {
      setClickLimit(prev => Math.min(prev + 500, 500)); // Instantly refill to the max limit, not exceeding 500
      setInstantRefillUses(prev => prev - 1); // Decrease usage
    } else {
      alert('No more instant refills left for today!');
    }
  };

  // Handle earn per click boost (4x for 20 seconds)
  const handleBoost = () => {
    if (boostUses > 0 && !boostActive) {
      setBoostActive(true);
      const originalEarnPerClick = earnPerClick;
      setEarnPerClick(earnPerClick * 4); // Set to 4x

      setTimeout(() => {
        setEarnPerClick(originalEarnPerClick); // Revert to original after 20 seconds
        setBoostActive(false);
      }, 20000); // 20 seconds boost duration

      setBoostUses(prev => prev - 1); // Decrease usage
    } else if (boostActive) {
      alert('Boost is already active!');
    } else {
      alert('No more boosts left for today!');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Upgrade Boosters</h3>

        {/* Earn Per Click Upgrade */}
        <div className="booster-item">
          <h4>Earn Per Click</h4>
          <p>Next Level: {earnPerClick + 1}</p>
          <button onClick={() => handleUpgradePurchase('earnPerClick')}>
            Upgrade for {getUpgradePrice('earnPerClick')} Points
          </button>
        </div>

        {/* Click Limit Upgrade */}
        <div className="booster-item">
          <h4>Click Limit</h4>
          <p>Next Limit: {clickLimit + 500}</p>
          <button onClick={() => handleUpgradePurchase('clickLimit')}>
            Upgrade for {getUpgradePrice('clickLimit')} Points
          </button>
        </div>

        {/* Recharge Rate Upgrade */}
        <div className="booster-item">
          <h4>Recharge Rate</h4>
          <p>Next Rate: {(rechargeRate + 0.1).toFixed(1)} per second</p>
          <button onClick={() => handleUpgradePurchase('rechargeRate')}>
            Upgrade for {getUpgradePrice('rechargeRate')} Points
          </button>
        </div>

        {/* Instant Refill Booster */}
        <div className="booster-item">
          <h4>Instant Refill</h4>
          <p>Description: Instantly refill click limit to 500.</p>
          <p>Uses Left: {instantRefillUses}/3</p>
          <button onClick={handleInstantRefill}>Use Instant Refill</button>
        </div>

        {/* 4x Earn Per Click Booster */}
        <div className="booster-item">
          <h4>4x Earn Per Click Boost</h4>
          <p>Description: Earn 4 times your click rate for 20 seconds.</p>
          <p>Uses Left: {boostUses}/3</p>
          <button onClick={handleBoost} disabled={boostActive}>
            {boostActive ? 'Boost Active!' : 'Use Boost'}
          </button>
        </div>

        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default BoostersModal;
