import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../assets/ankr.jpg'; // Update paths as needed
import walletIcon from '../assets/bounce.jpg';
import taskIcon from '../assets/forward.png';
import referIcon from '../assets/difinex.png';
import './BottomNavBar.css'; // For styling

const BottomNavBar = () => {
  return (
    <div className='bottom'>
    <div className="bottom-nav-bar">
      <Link to="/" className="nav-item">
        <img src={homeIcon} alt="Home" className="nav-icon" />
        <span className="nav-text">Earn</span>
      </Link>
      <Link to="/wallet" className="nav-item">
        <img src={walletIcon} alt="Wallet" className="nav-icon" />
        <span className="nav-text">Boost</span>
      </Link>
      <Link to="/task" className="nav-item">
        <img src={taskIcon} alt="Task" className="nav-icon" />
        <span className="nav-text">Task</span>
      </Link>
      <Link to="/refer" className="nav-item">
        <img src={referIcon} alt="Refer" className="nav-icon" />
        <span className="nav-text">Refer</span>
      </Link>
    </div>
    </div>
  );
};

export default BottomNavBar;
