// src/components/Wallet.js
import React from 'react';

const Wallet = () => {
  return (
    <div>
      <h2>Wallet</h2>
      <p>Manage your wallet here.</p>
    </div>
  );
};

export default Wallet;
