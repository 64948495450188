// src/components/Refer.js
import React from 'react';

const Refer = () => {
  return (
    <div>
      <h2>Refer</h2>
      <p>Share your referral link and earn bonuses.</p>
    </div>
  );
};

export default Refer;
