import React, { useState, useEffect } from 'react';
import './Bot.css'; // Import CSS for the bot component

const Bot = ({ points, setPoints, show,
    onClose, }) => {
  const [isPurchased, setIsPurchased] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const [offlineStartTime, setOfflineStartTime] = useState(null);
  const [showClaimModal, setShowClaimModal] = useState(false);

  // Load saved data from localStorage
  useEffect(() => {
    const savedIsPurchased = JSON.parse(localStorage.getItem('botIsPurchased')) || false;
    const savedOfflineStartTime = parseFloat(localStorage.getItem('botOfflineStartTime')) || null;
    const savedEarnedPoints = parseInt(localStorage.getItem('botEarnedPoints')) || 0;

    setIsPurchased(savedIsPurchased);
    setOfflineStartTime(savedOfflineStartTime);
    setEarnedPoints(savedEarnedPoints);

    // Check if the user came back online
    if (savedOfflineStartTime && !navigator.onLine) {
      const elapsed = Date.now() - savedOfflineStartTime;
      const earned = Math.min(Math.floor(elapsed / (1000 * 60 * 60)) * 100, 1200); // Max 1200 points in 12 hours
      setEarnedPoints(earned);
    }

    // Automatically show claim modal if there are earned points
    if (savedEarnedPoints > 0) {
      setShowClaimModal(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('botIsPurchased', JSON.stringify(isPurchased));
    localStorage.setItem('botOfflineStartTime', offlineStartTime);
    localStorage.setItem('botEarnedPoints', earnedPoints);
  }, [isPurchased, offlineStartTime, earnedPoints]);

  // Handle bot purchase
  const handlePurchase = () => {
    if (points >= 500000) {
      setPoints(prev => prev - 500000);
      setIsPurchased(true);
      setIsActive(true);
      setOfflineStartTime(Date.now());
      localStorage.setItem('botOfflineStartTime', Date.now());
    } else {
      alert('Not enough points to purchase the bot!');
    }
  };

  // Handle user coming back online
  useEffect(() => {
    const handleOnline = () => {
      if (offlineStartTime) {
        const elapsed = Date.now() - offlineStartTime;
        const earned = Math.min(Math.floor(elapsed / (1000 * 60 * 60)) * 100, 1200); // Max 1200 points in 12 hours
        setEarnedPoints(earned);
        setShowClaimModal(true);
      }
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [offlineStartTime]);

  const handleClaimPoints = () => {
    setPoints(prev => prev + earnedPoints);
    setEarnedPoints(0);
    setShowClaimModal(false);
    setOfflineStartTime(null);
    localStorage.removeItem('botOfflineStartTime');
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      {!isPurchased ? (
        <div className="modal-content">
          <h3>Purchase Bot</h3>
          <p>Price: 500,000 Points</p>
          <button onClick={handlePurchase}>Purchase Bot</button>
        </div>
      ) : (
        <div className="bot-status">
          <h3>Bot Status</h3>
          <p>Status: {isActive ? 'Active' : 'Inactive'}</p>
          <p>Earned Points: {earnedPoints}</p>
        </div>
      )}

      {showClaimModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Claim Earned Points</h3>
            <p>You have earned {earnedPoints} points while you were offline.</p>
            <button onClick={handleClaimPoints}>Claim Points</button>
            <button onClick={() => setShowClaimModal(false)}>Close</button>
          </div>
        </div>
      )}
         <button className="close-button" onClick={onClose}>Close</button>
    </div>
  );
};

export default Bot;
